import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import {
  ChartLine,
  CheckWaves,
  Layout,
  Search,
  Store,
} from "@mynaui/icons-react";
import { FlameIcon } from "lucide-react";
import { ChangeEvent, useState } from "react";
import { useEffect } from "react";
import { useDebounceValue } from "usehooks-ts";
import AccessControl from "./AccessControl";
import SearchDialogBrandsTab from "./SearchDialogBrandsTab";
import SearchDialogExpertsTab from "./SearchDialogExpertsTab";
import SearchDialogInspirationTab from "./SearchDialogInspirationTab";
import SearchDialogNewTemplatesTab from "./SearchDialogNewTemplatesTab";
import SearchDialogPopularTab from "./SearchDialogPopularTab";
import SearchDialogTemplateSection from "./SearchDialogTemplatesSection";
import TypingSearchView from "./TypingSearchView";
import { Stack } from "./custom-components";
import { LockTagSmall } from "./custom-components/LockTag";
import { CustomLightBulb } from "./shadcn/customLightBulb";
import { Dialog, DialogContent, DialogPortal } from "./shadcn/dialog";
import { Input } from "./shadcn/input";
import { ScrollArea } from "./shadcn/scroll-area";
import { Separator } from "./shadcn/separator";
import { SidebarMenuButton } from "./shadcn/sidebar";

export type PermissionData = {
  ID: string;
  email: string;
  userCanAccessAds: boolean;
  userCanAccessEverything: boolean;
  userCanAccessExperts: boolean;
  usersPlans: string[];
  // limitLeft: number;
  // hasRequestLimitReached: boolean;
  requestLimit: number;
  limitNextUpdateAt: string;
};

const SearchDialog = ({
  open,
  onOpenChange,
  permissions,
}: {
  open: boolean;
  onOpenChange: () => void;
  permissions: PermissionData;
}) => {
  const sidebarOptions = {
    nav: [
      { name: "Popular", icon: ChartLine },
      { name: "Templates", icon: Layout },
      { name: "Inspiration", icon: CustomLightBulb },
      { name: "Brands", icon: Store },
      { name: "Experts", icon: CheckWaves },
      { name: "New Templates", icon: FlameIcon },
    ],
  };
  const [subSection, setSubSection] = useState<string>(
    sidebarOptions.nav[0].name
  );

  const [searchTerm, setSearchTerm] = useDebounceValue("", 600);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDebouncedSearchTerm(e.target.value); // Update debounced term first
  };

  return (
    <Dialog modal={false} open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogContent
          className={` gap-1 overflow-hidden p-0 pl-6 pt-4 h-full max-h-[75svh] lg:max-h-[49rem] !rounded-3xl w-11/12 md:max-w-[700px] lg:max-w-3xl lg:[&>button]:hidden z-[31] bg-themebackground dark:bg-themebackgrounddark flex flex-col`}
        >
          <Stack className="h-fit">
            <div className="outline-none border-none flex gap-3 items-center w-11/12 lg:w-auto">
              {/* Search Icon positioned on the left */}
              <Search className="h-7 w-7" aria-hidden="true" />
              <Input
                type="text"
                value={debouncedSearchTerm}
                onChange={handleSearchChange}
                placeholder="Templates, Inspiration, Experts, Collections, and more..."
                className="p-0 lg:w-full outline-none border-0 focus-visible:ring-offset-0 focus-visible:ring-0 shadow-none"
              />
            </div>
            <div className="pr-6 py-3">
              <Separator />
            </div>
          </Stack>

          {debouncedSearchTerm ? (
            <TypingSearchView
              searchTerm={searchTerm}
              debouncedSearchTerm={debouncedSearchTerm}
            />
          ) : (
            <div className="flex flex-col h-full lg:flex-row gap-2.5 w-full pb-5 flex-1">
              {/* Sidebar */}
              <div className="w-full lg:max-w-48 lg:block hidden">
                <div className="flex flex-row flex-wrap lg:flex-nowrap lg:flex-col gap-2 w-full">
                  {sidebarOptions.nav.map((item) => (
                    <AccessControl
                      feature={
                        item.name === "Brands"
                          ? CreativeOSFeatures.SHOW_SEARCH_BRANDS_BUTTON
                          : item.name == "Experts"
                            ? CreativeOSFeatures.SHOW_SEARCH_EXPERTS_BUTTON
                            : undefined
                      }
                      FeatureUnlocked={
                        <SidebarMenuButton
                          key={item.name}
                          isActive={item.name === subSection}
                          onClick={() => setSubSection(item.name)}
                          className={`w-fit lg:w-full ${
                            item.name === "Brands" || item.name == "Experts"
                              ? "hidden lg:flex"
                              : ""
                          }`}
                        >
                          <item.icon />
                          <span className="w-full">{item.name}</span>
                        </SidebarMenuButton>
                      }
                      FeatureLocked={
                        <SidebarMenuButton
                          key={item.name}
                          className={`opacity-50 ${
                            item.name === "Brands" || item.name == "Experts"
                              ? "hidden lg:flex"
                              : ""
                          }`}
                        >
                          <item.icon />
                          <span className="w-full">{item.name}</span>
                          <LockTagSmall />
                        </SidebarMenuButton>
                      }
                    />
                  ))}
                </div>
              </div>

              <ScrollArea
                type="always"
                className="w-full flex-1 max-h-[75svh] lg:max-h-[85svh] pb-10"
              >
                <div className="w-full block lg:hidden mb-5">
                  <div className="flex flex-row flex-wrap lg:flex-nowrap lg:flex-col gap-2 w-full">
                    {sidebarOptions.nav.map((item) => (
                      <AccessControl
                        feature={
                          item.name === "Brands"
                            ? CreativeOSFeatures.SHOW_SEARCH_BRANDS_BUTTON
                            : item.name == "Experts"
                              ? CreativeOSFeatures.SHOW_SEARCH_EXPERTS_BUTTON
                              : undefined
                        }
                        FeatureUnlocked={
                          <SidebarMenuButton
                            key={item.name}
                            isActive={item.name === subSection}
                            onClick={() => setSubSection(item.name)}
                            className={`w-fit lg:w-full ${
                              item.name === "Brands" || item.name == "Experts"
                                ? "hidden lg:flex"
                                : ""
                            }`}
                          >
                            <item.icon />
                            <span className="w-full">{item.name}</span>
                          </SidebarMenuButton>
                        }
                        FeatureLocked={
                          <SidebarMenuButton
                            key={item.name}
                            className={`opacity-50 ${
                              item.name === "Brands" || item.name == "Experts"
                                ? "hidden lg:flex"
                                : ""
                            }`}
                          >
                            <item.icon />
                            <span className="w-full">{item.name}</span>
                            <LockTagSmall />
                          </SidebarMenuButton>
                        }
                      />
                    ))}
                  </div>
                </div>
                {subSection === "Popular" ? (
                  <SearchDialogPopularTab searchItem={setDebouncedSearchTerm} />
                ) : subSection === "Templates" ? (
                  <SearchDialogTemplateSection permissions={permissions} />
                ) : subSection === "Inspiration" ? (
                  <SearchDialogInspirationTab />
                ) : subSection === "Brands" ? (
                  <SearchDialogBrandsTab />
                ) : subSection === "Experts" ? (
                  <SearchDialogExpertsTab permissions={permissions} />
                ) : subSection === "New Templates" ? (
                  <SearchDialogNewTemplatesTab />
                ) : null}
              </ScrollArea>
            </div>
          )}
        </DialogContent>

        <div
          className="fixed inset-0 bg-black/10 backdrop-blur-sm z-[30]"
          onClick={onOpenChange}
        />
      </DialogPortal>
    </Dialog>
  );
};

export default SearchDialog;
