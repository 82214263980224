import { useNavigate } from '@tanstack/react-router';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useReadLocalStorage } from 'usehooks-ts';

export const ProtectedArea: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const user = useReadLocalStorage<{
    email: string;
    id: string;
    firstName: string;
    lastName: string;
  }>('_wos_user');

  useEffect(() => {
    // Check if the authToken exists
    if (user) {
      setIsAuthenticated(true);
    } else if (location.pathname === '/reactivation') {
      navigate({
        to: '/login',
        search: () => {
          return {
            redirect: '/reactivation',
          };
        },
      });
    } else {
      // Navigate to login page if not authenticated
      navigate({ to: '/login' });
    }
  }, [navigate]);

  // Show loading or a placeholder while authentication is being checked
  if (!isAuthenticated) return <div></div>;

  // If authenticated, render the children (protected content)
  return <>{children}</>;
};
