import { Loader } from '@/components/custom-components/Loader';
import OnboardingHeader from '@/components/onboarding/OnboardingHeader.tsx';
import { ProtectedArea } from '@/components/protectedArea';
import { Button } from '@/components/shadcn/button';
import { useDowngradeUserPlan } from '@/hooks/useDowngradeUserPlan';
import useUserPermissions from '@/hooks/useUserPermissions';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute('/reactivation')({
  component: Reactivation,
});

function Reactivation() {
  const navigate = useNavigate();

  const { activateToFree, activateToFreeIsPending, isActivationSuccess } =
    useDowngradeUserPlan();

  const { permissions } = useUserPermissions();

  useEffect(() => {
    if (permissions && permissions.usersPlans.length === 0) {
      activateToFree({
        plans: ['creative-os-free-plan'],
        coupons: undefined,
      });
    } else {
      toast.info('Your account is already on a plan');
      setTimeout(() => {
        navigate({ to: '/feeds/templates' });
      }, 2000);
    }
  }, [permissions?.usersPlans.length]);

  return (
    <ProtectedArea>
      <div
        className={'bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen'}
      >
        <div>
          <OnboardingHeader />
        </div>
        <div
          className={
            'flex-1 flex flex-col justify-center items-center py-[7.75rem]'
          }
        >
          <div
            className={
              'rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border'
            }
          >
            <div className={'flex flex-col items-start gap-2'}>
              <h4
                className={
                  'text-2xl text-center text-themeforeground font-semibold'
                }
              >
                Account Reactivation
              </h4>
            </div>
            <div>
              {activateToFreeIsPending ? (
                <div className="flex justify-center items-center w-full py-6 h-14">
                  <Loader />
                </div>
              ) : (
                isActivationSuccess && (
                  <div className="text-center">
                    <h3 className="font-sembold text-lg">Welcome back!</h3>
                    <p>We have reactivated your account to a Free Plan.</p>
                    <div className={'mt-6'}>
                      <Button
                        onClick={() => {
                          window.location.href = '/feeds/templates';
                        }}
                        type={'button'}
                        className="w-full"
                      >
                        {'Start Creating'}
                      </Button>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </ProtectedArea>
  );
}
