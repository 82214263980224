import { defaultUseQueryRefetchOptions } from '@/_shared/constants';
import { trpc } from '@/utils/trpc';
import { ArrowRight } from '@mynaui/icons-react';
import { Link } from '@tanstack/react-router';
import SearchDialogAdTemplateImage from './SearchDialogAdTemplateImage';
import SearchDialogEmailLanderTemplateImage from './SearchDialogEmailTemplateImage';
import { ScrollArea, ScrollBar } from './shadcn/scroll-area';
import { Skeleton } from './shadcn/skeleton';

const SearchDialogNewTemplatesTab = () => {
  // Inspirations
  const { data: newAdTemplates, isLoading: adTemplatesIsLoading } =
    trpc.filterTemplatesByCollections.useQuery({
      sortingOptions: 'Recent',
      limit: 4,
      Ready: true,
      Tags: undefined,
      collectionIds: [],
    });

  const { data: newEmailTemplates, isLoading: emailTemplatesIsLoading } =
    trpc.emails.useQuery(
      {
        sortingOptions: 'Recent',
        limit: 4,
      },
      defaultUseQueryRefetchOptions
    );

  const { data: newLandersTemplates, isLoading: landerTemplatesIsLoading } =
    trpc.landingPages.useQuery(
      {
        sortingOptions: 'Recent',
        limit: 4,
      },
      defaultUseQueryRefetchOptions
    );
  return (
    <div className="space-y-2.5 pr-5">
      <div className="space-y-2.5">
        <h6 className="text-sm">Ads</h6>
        <div className="">
          {adTemplatesIsLoading ? (
            <div className="grid grid-cols-4 gap-2.5">
              {[...Array(4)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="w-full h-[7.25rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="grid grid-cols-4 gap-2.5">
                {newAdTemplates?.ATRecords.map((item) => (
                  <SearchDialogAdTemplateImage
                    key={item['Creative ID']}
                    item={{
                      ...item,
                      form: '',
                      'Example Image': [],
                      'Template Image': [],
                      Status: '',
                      AdDbId: null,
                      Expert: undefined,
                      Tags: undefined,
                      Liked: item.Liked ?? false,
                      'Canva Template LInk': item['Canva Template LInk'] ?? '',
                    }}
                    isEqualWidth
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>
        <div className="flex justify-end mr-3">
          <Link
            className="text-slate-500"
            to="/feeds/inspiration"
            search={{ orderFilter: 'Recent' }}
          >
            <span className="flex items-center gap-2 text-sm">
              <span>See all</span> <ArrowRight size={'1rem'} />
            </span>
          </Link>
        </div>
      </div>
      <div className="space-y-2.5">
        <h6 className="text-sm">Emails</h6>
        <div className="">
          {emailTemplatesIsLoading ? (
            <div className="grid grid-cols-4 gap-2.5">
              {[...Array(4)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="w-full h-[7.25rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="grid grid-cols-4 gap-2.5">
                {newEmailTemplates?.Emails.map((item) => (
                  <SearchDialogEmailLanderTemplateImage
                    key={item['Email ID']}
                    item={{
                      Screenshot: item['Email Screenshot'],
                      ID: item['Email ID'].toString(),
                      created: item.Created,
                      category: item.Category ?? '',
                      isSaved: item.isSaved,
                      imageUrl: item.imageUrl,
                      brandId: item.brandId,
                      brandName: item.brandName,
                      brandImage: item.brandImage,
                    }}
                    type="email"
                    isEqualWidth
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>
        <div className="flex justify-end mr-3">
          <Link
            className="text-slate-500"
            to="/feeds/inspiration/emails"
            search={{ orderFilter: 'Newest' }}
          >
            <span className="flex items-center gap-2 text-sm">
              <span>See all</span> <ArrowRight size={'1rem'} />
            </span>
          </Link>
        </div>
      </div>
      <div className="space-y-2.5">
        <h6 className="text-sm">Landers</h6>
        <div className="">
          {landerTemplatesIsLoading ? (
            <div className="grid grid-cols-4 gap-2.5">
              {[...Array(4)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="w-full h-[7.25rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="grid grid-cols-4 gap-2.5">
                {newLandersTemplates?.LandingPages.map((item) => (
                  <SearchDialogEmailLanderTemplateImage
                    key={item['LP ID']}
                    item={{
                      Screenshot: item['Landing Page Screenshot'],
                      ID: item['LP ID'].toString(),
                      created: item.Created,
                      category: item.Category ?? '',
                      isSaved: item.isSaved,
                      imageUrl: item.imageUrl,
                      brandId: item.brandId,
                      brandName: item.brandName,
                      brandImage: item.brandImage,
                    }}
                    type="landing-page"
                    isEqualWidth
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>
        <div className="flex justify-end mr-3">
          <Link
            className="text-slate-500"
            to="/feeds/inspiration/landing-pages"
            search={{ orderFilter: 'Newest' }}
          >
            <span className="flex items-center gap-2 text-sm">
              <span>See all</span> <ArrowRight size={'1rem'} />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchDialogNewTemplatesTab;
