import { defaultUseQueryRefetchOptions } from "@/_shared/constants";
import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from "@/components/ResponsiveMasonryWrapper";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { AdFilter, Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { InspirationRequestNotifier } from "@/components/custom-components/InspirationRequestNotifier";
import { Loader } from "@/components/custom-components/Loader";
import NotFoundBox from "@/components/custom-components/NotFound";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  AdFeedFilterOption,
  AdSelectedFilters,
  TemplateAdSearchParams,
} from "@/hooks/useFilterFeed.tsx";
import { otherFilterOptions } from "@/utils/data/filters.ts";
import { trpc } from "@/utils/trpc.ts";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import {
  FormatFilter,
  StatusFilters,
  TimeLiveFilters,
} from "../../../../src/server/types";
import { orderFilter as OrderFilterType } from "../../../../src/shared/airtable";

import { DiscoverAdCard } from "@/components/templates/AdCard";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions";
import useUserPermissions from "@/hooks/useUserPermissions";

export const Route = createFileRoute("/feeds/search/inspiration/ads")({
  component: All,
  validateSearch: (search: Record<string, unknown>): TemplateAdSearchParams => {
    const industry =
      typeof search?.industry === "string" ? search.industry : undefined;
    const formats =
      typeof search?.formats === "string" ? search.formats : undefined;
    const timeLive =
      typeof search?.timeLive === "string" ? search.timeLive : undefined;
    const statuses =
      typeof search?.statuses === "string" ? search.statuses : undefined;
    const brands =
      typeof search?.brands === "string" ? search.brands : undefined;
    const sideBarOpen =
      typeof search?.sideBarOpen === "boolean" ? search.sideBarOpen : undefined;
    const searchTerm: string | undefined =
      typeof search.searchTerm === "string" ? search.searchTerm : undefined;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      industry,
      formats,
      timeLive,
      statuses,
      sideBarOpen,
      orderFilter,
      brands,
      searchTerm,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    industry: queryIndustry,
    formats: queryFormats,
    timeLive: queryTimeLive,
    statuses: queryStatus,
    searchTerm: querySearchTerm,
    orderFilter,
    brands: queryBrands,
  } = Route.useSearch();

  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);

  const [selectedFilters, setSelectedFilters] = useState<AdSelectedFilters>({
    categories: queryIndustry ? queryIndustry.split(",") : undefined,
    formats: queryFormats
      ? (queryFormats.split(",") as FormatFilter[])
      : undefined,
    timeLive: queryTimeLive
      ? (queryTimeLive.split(",") as TimeLiveFilters[])
      : undefined,
    statuses: queryStatus
      ? (queryStatus.split(",") as StatusFilters[])
      : undefined,
    brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
  });

  // Get the page industry to be passed to the filter
  const { data: pageIndustry } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const { data: brands } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions
  );

  useEffect(() => {
    if (pageIndustry && brands && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Avoid redundant updates
        return [
          ...otherFilterOptions,
          {
            title: "Industry",
            counter: 0,
            optionItems: pageIndustry.map((i) => ({
              label: i.name,
              value: false,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: brands.map((i) => ({
              label: i.brandName,
              logo: i.brandImage,
              value: false,
              id: i.brandId,
            })),
          },
        ];
      });
    }
  }, [pageIndustry, brands, filterOptions.length]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Industry" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")) ||
          (option.title === "Format" &&
            selectedFilters.formats?.includes(item.label as FormatFilter)) ||
          (option.title === "Time Live" &&
            selectedFilters.timeLive?.includes(
              item.label as TimeLiveFilters
            )) ||
          (option.title === "Status" &&
            selectedFilters.statuses?.includes(item.label as StatusFilters)),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev;
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: {
      industry?: string;
      formats?: string;
      timeLive?: string;
      statuses?: string;
      brands?: string;
      searchTerm?: string;
    }) => {
      const searchParams = new URLSearchParams();

      if (params.industry) searchParams.set("industry", params.industry);
      if (params.brands) searchParams.set("brands", params.brands);
      if (params.formats) searchParams.set("formats", params.formats);
      if (params.timeLive) searchParams.set("timeLive", params.timeLive);
      if (params.statuses) searchParams.set("statuses", params.statuses);
      if (params.searchTerm) searchParams.set("searchTerm", params.searchTerm);

      navigate({
        to: "/feeds/search/inspiration/ads",
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
      });
    },
    [navigate]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedIndustry: string[] = [];
      const selectedBrands: string[] = [];
      const selectedFormats: FormatFilter[] = [];
      const selectedTimeLive: TimeLiveFilters[] = [];
      const selectedStatus: StatusFilters[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Industry") selectedIndustry.push(item.label);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
            if (group.title === "Format")
              selectedFormats.push(item.label as FormatFilter);
            if (group.title === "Time Live")
              selectedTimeLive.push(item.label as TimeLiveFilters);
            if (group.title === "Status")
              selectedStatus.push(item.label as StatusFilters);
          }
        });
      });

      setSelectedFilters({
        categories: selectedIndustry.length > 0 ? selectedIndustry : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
        formats: selectedFormats.length > 0 ? selectedFormats : undefined,
        timeLive: selectedTimeLive.length > 0 ? selectedTimeLive : undefined,
        statuses: selectedStatus.length > 0 ? selectedStatus : undefined,
      });

      updateQueryString({
        industry:
          selectedIndustry.length > 0 ? selectedIndustry.join(",") : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
        formats:
          selectedFormats.length > 0 ? selectedFormats.join(",") : undefined,
        timeLive:
          selectedTimeLive.length > 0 ? selectedTimeLive.join(",") : undefined,
        statuses:
          selectedStatus.length > 0 ? selectedStatus.join(",") : undefined,
      });
    },
    [updateQueryString]
  );

  const { userLimits } = useUserPermissions();

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.industry = selectedFilters.categories.join(",");
    if (selectedFilters.brands)
      params.brands = selectedFilters.brands.join(",");
    if (selectedFilters.formats)
      params.formats = selectedFilters.formats.join(",");
    if (selectedFilters.timeLive)
      params.timeLive = selectedFilters.timeLive.join(",");
    if (selectedFilters.statuses)
      params.statuses = selectedFilters.statuses.join(",");
  }, [selectedFilters]);

  const {
    data: inspirationFeed,
    isLoading,
    refetch,
    isError,
  } = trpc.searchAdInspiration.useQuery(
    {
      limit: 20,
      query: querySearchTerm ?? "",
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    refetch();
  }, [querySearchTerm]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }
  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="relative gap-3 lg:gap-8">
        <FeatureTabs
          defaultOrderValue={"Random"}
          tabItems={[
            {
              name: "Ads",
              link: "/feeds/search/inspiration/ads",
              searchParams: { searchTerm: querySearchTerm },
            },
            {
              name: "Emails",
              link: "/feeds/search/inspiration/emails",
              searchParams: { searchTerm: querySearchTerm },
            },
            {
              name: "Landers",
              link: "/feeds/search/inspiration/landing-pages",
              searchParams: { searchTerm: querySearchTerm },
            },
          ]}
        />
        <Stack className="gap-3 lg:gap-6 relative">
          <div
            className={
              "flex justify-between gap-5 items-center flex-wrap xs:flex-nowrap"
            }
          >
            <Text weight="semibold" size={"xxl"} className="w-fit">
              Your Discovery Results{" "}
              {inspirationFeed ? `(${inspirationFeed?.totalHits})` : ""}:
              <span className="opacity-45">“{querySearchTerm}”</span>
            </Text>
            <div className={"hidden gap-2"}>
              <div className="lg:hidden">
                {
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={[
                      "Random",
                      "Recent",
                      "Oldest",
                      "Popular",
                      "Most Requested",
                    ]}
                  />
                }
              </div>
            </div>
          </div>
        </Stack>
      </Stack>

      <Stack className="w-full gap-3">
        <Text weight="medium" size={"sm"} className="w-fit hidden">
          Narrow your search using filters:
        </Text>

        <div
          className={`lg:sticky lg:top-0 lg:z-10 lg:bg-themebackground dark:lg:bg-themebackgrounddark  lg:py-2 hidden ${pageIndustry && filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-start gap-1.5`}
        >
          {pageIndustry && filterOptions && filterOptions.length > 0 && (
            <AdFilter
              initialOptions={filterOptions}
              onOptionsChange={handleOptionsChange}
            />
          )}

          <div className={"hidden lg:flex"}>
            <OrderFilterSelect
              defaultFilter={orderFilter || "Random"}
              options={[
                "Random",
                "Recent",
                "Oldest",
                "Popular",
                "Most Requested",
              ]}
            />
          </div>
        </div>
      </Stack>
      {userLimits ? (
        <InspirationRequestNotifier
          pageType="ad"
          limitLeft={userLimits.request.ad}
        />
      ) : null}
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div>
          {inspirationFeed && inspirationFeed.data.length === 0 ? (
            <NotFoundBox />
          ) : (
            <div ref={squareRef} className={"relative w-full lg:px-0 pb-10"}>
              {inspirationFeed && (
                <div>
                  <TypedResponsiveMasonry
                    columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                  >
                    <MasonryComponent gutter={gutterWidth / 16 + "rem"}>
                      {inspirationFeed.data.map((ad) => {
                        return (
                          <DiscoverAdCard
                            key={ad.id}
                            DesiredWidth={desiredCardWidth}
                            adData={{
                              id: ad.id,
                              reach: null,
                              earliestView: "",
                              adRunningDays: 0,
                              imageUrl: ad.imageUrl ?? null,
                              brandId: ad.brandId ?? null,
                              brandName: ad.brandName ?? null,
                              brandImage: ad.brandImage ?? null,
                              videoUrl: ad.videoUrl ?? null,
                              isSaved: ad.isSaved ?? false,
                            }}
                            IsPublic={false}
                            IsLocked={false}
                          />
                        );
                      })}
                    </MasonryComponent>
                  </TypedResponsiveMasonry>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Stack>
  );
}

export default All;
