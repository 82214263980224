import { CreativeOSFeatures } from '@/_shared/subscriptionPlans';
import { useState } from 'react';
import AccessControl from './AccessControl';
import TemplateDetails from './TemplateDetails';
import { LockTagLargeNoBg } from './custom-components/LockTag';
import { LandingAdDataProps } from './templates/LandingAdCard';

const SearchDialogEmailLanderTemplateImage = ({
  item,
  type,
  feature,
  isEqualWidth,
}: {
  item: LandingAdDataProps;
  type?: 'email' | 'landing-page';
  collections?: string[];
  feature?: CreativeOSFeatures;
  isEqualWidth?: boolean;
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div className={`bg-slate-100 rounded-2xl`} key={item.ID}>
      {detailsShow && item ? (
        <TemplateDetails
          landingData={item}
          type={type}
          open={detailsShow}
          onCloseDrawer={() => setDetailsShow(false)}
        />
      ) : null}
      <AccessControl
        FeatureUnlocked={
          <img
            src={
              item.imageUrl ??
              'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
            }
            alt={`${item.brandName ?? 'CreativeOs'} Image`}
            onClick={() => setDetailsShow(true)}
            loading="eager"
            className={`${isEqualWidth ? 'w-full h-[7.25rem]' : 'min-w-[10.7rem] w-[10.7rem] h-[10.7rem]'} rounded-2xl object-cover cursor-pointer lg:hvr-grow`}
          />
        }
        feature={
          feature ??
          (type == 'email'
            ? CreativeOSFeatures.SHOW_SEARCH_EMAIL_TEMPLATE_IMAGE
            : CreativeOSFeatures.SHOW_SEARCH_LANDER_TEMPLATE_IMAGE)
        }
        FeatureLocked={
          <div className="relative">
            <img
              src={
                item.imageUrl ??
                'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
              }
              alt={`${item.brandName ?? 'CreativeOs'} Image`}
              loading="eager"
              className={`${isEqualWidth ? 'w-full h-[7.25rem]' : 'min-w-[10.7rem] w-[10.7rem] h-[10.7rem]'}  rounded-2xl object-cover cursor-pointer lg:hvr-grow blur-sm`}
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-black bg-opacity-50 rounded-full p-2">
                <LockTagLargeNoBg />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SearchDialogEmailLanderTemplateImage;
