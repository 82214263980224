import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App.tsx";
import "./index.css";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import * as Sentry from "@sentry/react";
import { UserbackProvider } from "@userback/react";
import { CustomUserbackProvider } from "./contexts/CustomUserbackContext.tsx";

let user = null;
try {
  user = JSON.parse(localStorage.getItem("_wos_user") || "null");
} catch (error) {
  console.error("Failed to parse user from localStorage", error);
  localStorage.clear();
  window.location.href = "/login";
}

if (import.meta.env.PROD || import.meta.env.MODE === "staging") {
  Sentry.init({
    dsn: "https://76b8f4994a4cf36b4ce3b5edc8797646@o4506757010227200.ingest.sentry.io/4506796685983744",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /.*api\.creativeos.*/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  if (user) {
    Sentry.setUser({ email: user.email });
  }
}

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  person_profiles: "identified_only",
  disable_session_recording:
    import.meta.env.DEV ||
    (import.meta.env.PROD && import.meta.env.MODE === "staging"),
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <UserbackProvider
        token={import.meta.env.VITE_USERBACK_TOKEN}
        options={{
          name: (user && `${user.firstName} ${user.lastName}`) || undefined,
          email: (user && `${user.email}`) || undefined,
        }}
      >
        <CustomUserbackProvider>
          <App />
        </CustomUserbackProvider>
      </UserbackProvider>
    </PostHogProvider>
  </React.StrictMode>
);
