import { Text } from "@/components/custom-components";
import { Loading } from "@/components/loading.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";
import { Input } from "@/components/shadcn/input.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { trpc } from "@/utils/trpc.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { CheckCircle2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export type BoardProp = {
  description?: string | null;
  name: string;
  id: string;
  isInBoard?: boolean;
};

export const BoardDialog = ({
  data,
  open,
  onOpenChange,
  adId,
  inspirationLanderId,
  templateId,
  emailTemplateId,
  landerTemplateId,
}: {
  data?: BoardProp;
  open: boolean;
  onOpenChange: () => void;
  adId?: number;
  inspirationLanderId?: number;
  templateId?: string;
  emailTemplateId?: string;
  landerTemplateId?: string;
}) => {
  const utils = trpc.useUtils();
  const navigate = useNavigate();

  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { mutateAsync: addAdToBoard } = trpc.addAdToBoard.useMutation();
  const { mutateAsync: addLanderToBoard } = trpc.addLanderToBoard.useMutation();
  const { mutateAsync: addEmailTemplateToBoard } =
    trpc.addEmailTemplateToBoard.useMutation();
  const { mutateAsync: addLandingPageTemplateToBoard } =
    trpc.addLandingPageTemplateToBoard.useMutation();

  const { mutateAsync: addTemplateToBoard } =
    trpc.addTemplateToBoard.useMutation();

  const { mutate: createBoard, isPending: createIsPending } =
    trpc.createBoard.useMutation({
      onSuccess: async (data) => {
        setMessage("Collection created successfully");
        await utils.getUserLimits.invalidate();
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            if (adId) {
              // we directly add the ad to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addAdToBoard({
                boardId: data.id,
                adId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (templateId) {
              // we directly add the template to the collection if created from the ad template card
              setMessage(null);
              setLoading(true);
              addTemplateToBoard({
                boardId: data.id,
                templateId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (inspirationLanderId) {
              // we directly add the inspirationLander to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addLanderToBoard({
                boardId: data.id,
                landerId: inspirationLanderId,
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (emailTemplateId) {
              // we directly add the emailTemplate to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addEmailTemplateToBoard({
                boardId: data.id,
                templateId: parseInt(emailTemplateId),
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else if (landerTemplateId) {
              // we directly add the landerTemplate to the collection if created from the inspiration card
              setMessage(null);
              setLoading(true);
              addLandingPageTemplateToBoard({
                boardId: data.id,
                templateId: parseInt(landerTemplateId),
              }).then(() => {
                setLoading(false);
                setMessage(`Added to ${data.name} collection!`);
                utils.getBoards.invalidate();
                showToastNotification("success", {
                  message: `Added to ${data.name} collection!`,
                });
                setTimeout(() => {
                  onOpenChange();
                }, 1000);
              });
            } else {
              // we navigate to the collection's page
              setTimeout(() => {
                onOpenChange();
                navigate({
                  to: `/feeds/boards/$boardID/ads`,
                  params: {
                    boardID: data.id,
                  },
                });
              }, 1000);
            }
          });
      },
      onError: (error) => {
        showToastNotification("error", {
          message: `Error creating collection.`,
          description: `${error.message}`,
        });
      },
    });

  const { mutate: editBoard, isPending: editIsPending } =
    trpc.editBoard.useMutation({
      onSuccess: async () => {
        setMessage("Collection updated successfully");
        await utils.getBoardDetails
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
            }, 2000);
          });
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
            }, 2000);
          });
      },
    });

  const formSchema = z.object({
    name: z.string().min(1, "Collection name is required"),
    description: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: data?.name || "",
      description: data?.description || "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (data) {
        editBoard({ ...values, boardId: data.id });
      } else createBoard(values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={open || editIsPending || createIsPending || loading}
      onOpenChange={onOpenChange}
    >
      <DialogContent
        className={"gap-6 bg-themebackground dark:bg-themebackgrounddark"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <DialogHeader>
          <DialogTitle>
            <Text weight={"semibold"} size={"lg"} className="leading-7">
              {data
                ? "Edit Collections"
                : loading
                  ? "Adding ad to collection"
                  : "Create Collection"}
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : editIsPending || createIsPending || loading ? (
          <div className={"flex flex-col gap-3 w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className={""}>
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium leading-5">
                        Collection Name
                      </FormLabel>
                      <FormControl>
                        <Input
                          className="shadow-none"
                          placeholder={"Name"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Collection Description</FormLabel>
                      <FormControl>
                        <Input
                          className="shadow-none"
                          placeholder={"Description"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className={"flex justify-end gap-2"}>
                  <Button
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"outline"}
                    onClick={() => onOpenChange()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"default"}
                  >
                    {createIsPending || editIsPending
                      ? "Submitting..."
                      : "Save"}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};
